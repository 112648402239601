export default {
  namespaced: true,
  state: {
    _loading: {
      login: false,
      logout: false
    },
    id: 0,
    class: "",
    code: "",
    district: "",
    email: "",
    name: "",
    race: "",
    school: "",
    state: "",
    prize: null,
    quiz: [],
    entry: null,
    locale: "en"
  },
  mutations: {
    selectLocale(state, payload) {
      state.locale = payload;
    },
    customerRegister(state, payload) {
      state.id = payload.id;
      state.class = payload.class;
      state.code = payload.code;
      state.district = payload.district;
      state.email = payload.email;
      state.name = payload.name;
      state.race = payload.race;
      state.school = payload.school;
      state.state = payload.state;
    },
    // Stores customer information upon login
    customerLogin(state, payload) {
      state.id = payload.user.id;
      state.class = payload.user.class;
      state.code = payload.user.code;
      state.district = payload.user.district;
      state.email = payload.user.email;
      state.name = payload.user.name;
      state.race = payload.user.race;
      state.school = payload.user.school;
      state.state = payload.user.state;
      state.prize = payload.user.prize || null;
      state.quiz = payload.quiz || [];
      state.entry = payload.entry || null;
    },
    completeQuiz(state, payload) {
      state.quiz.push({
        level: payload.level,
        isDone: true
      });
    },
    winPrize(state) {
      state.prize = {
        prize: true,
        text: "won"
      };
    },
    deletePrize(state) {
      state.prize = null;
    },
    addEntry(state) {
      state.entry = {
        date: new Date()
      };
    },
    deleteEntry(state) {
      state.entry = null;
    },
    // Deletes customer information upon logout
    delete(state) {
      state.id = 0;
      state.class = "";
      state.code = "";
      state.district = "";
      state.email = "";
      state.name = "";
      state.race = "";
      state.school = "";
      state.state = "";
      state.prize = null;
      state.quiz = [];
      state.entry = null;
    }
  }
};
