// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import FlagIcon from "vue-flag-icon";
import moment from "moment";
import helpers from "@/plugins/helpers";
import VueSweetalert2 from "vue-sweetalert2";
import Pagination from "vue-pagination-2";
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

const options = {
  confirmButtonColor: '#006994',
};
Vue.component("pagination", Pagination);
Vue.use(VueSweetalert2, options);
Vue.use(VueLoading);
Vue.use(helpers, {
  $store: store,
  $router: router,
  $i18n: i18n,
  $swal: Vue.swal,
  $moment: moment
});

Vue.prototype.$moment = moment;

Vue.config.productionTip = false;

import vClickOutside from "v-click-outside";

Vue.use(vClickOutside);

Vue.use(FlagIcon);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");