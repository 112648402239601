import Vue from "vue";
import Router from "vue-router";
import store from "./store";
Vue.use(Router);

const routes = [
  {
    path: "/auth",
    component: () => import("@/views/auth/Index"),
    children: [
      {
        name: "Login",
        path: "/login",
        component: () => import("@/views/auth/Login")
      },
      {
        name: "Register",
        path: "/register",
        component: () => import("@/views/auth/Register")
      },
      {
        name: "Forgot Password",
        path: "/forgotpw",
        component: () => import("@/views/auth/ForgotPassword")
      },
      {
        name: "Reset Password",
        path: "/resetpw",
        component: () => import("@/views/auth/ResetPassword")
      }
    ]
  },
  {
    path: "/quiz-main",
    component: () => import("@/views/quiz/Index"),
    children: [
      {
        name: "QuizMain",
        path: "",
        component: () => import("@/views/quiz/Main")
      },
      {
        name: "Quiz1",
        path: "1",
        component: () => import("@/views/quiz/One")
      },
      {
        name: "Quiz2",
        path: "2",
        component: () => import("@/views/quiz/Two")
      },
      {
        name: "Quiz3",
        path: "3",
        component: () => import("@/views/quiz/Three")
      },
      {
        name: "QuizDone",
        path: "win",
        component: () => import("@/views/quiz/Win")
      }
    ]
  },
  {
    path: "/",
    redirect: "/",
    component: () => import("@/views/index"),
    children: [
      // routes for home folder
      {
        name: "Home",
        path: "/",
        component: () => import("@/views/home/HomePage")
      },
      {
        name: "Profile",
        path: "/profile",
        component: () => import("@/views/auth/Profile")
      },
      {
        name: "Quiz",
        path: "/quiz",
        component: () => import("@/views/home/Quiz")
      },
      {
        name: "Competition",
        path: "/competition",
        component: () => import("@/views/home/Competition")
      },
      {
        name: "Moment",
        path: "/moment",
        component: () => import("@/views/home/Moment")
      },
      {
        name: "Mom",
        path: "/survey",
        component: () => import("@/views/home/Mom")
      },
      {
        name: "Contact",
        path: "/contact",
        component: () => import("@/views/home/Contact")
      }
    ]
  }
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  var isAuthenticated = store.getters.isLoggedIn;

  if (
    !isAuthenticated &&
    ["QuizMain", "Quiz1", "Quiz2", "Quiz3", "QuizDone"].includes(to.name)
  )
    next({ name: "Login" });
  else next();
});

export default router;
