import Vue from "vue";
import Vuex from "vuex";
import VueResource from "vue-resource";
import VuexPersistence from "vuex-persist";

import Cookies from "js-cookie";

import customer from "@/store/_customer";

Vue.use(VueResource);
Vue.use(Vuex);

const storageKey = "uOEOCRFfVy";

const localStorage = new VuexPersistence({
  key: storageKey,
  storage: window.localStorage
  // reducer: state => ({
  //   customer: state.customer
  // })
});

const sessionStorage = new VuexPersistence({
  key: storageKey,
  storage: window.sessionStorage,
  reducer: state => ({
    customer: state.customer
  })
});

const store = new Vuex.Store({
  modules: {
    customer
  },
  getters: {
    userId: state => {
      return state.customer.id;
    },
    currentLocale: state => {
      return state.customer.locale;
    },
    isLoggedIn: state => {
      if (state.customer.id) {
        return true;
      } else {
        return false;
      }
    },
    isWonPrize: state => {
      if (state.customer.prize) return true;
    },
    isQuizDone: state => {
      return state.customer.quiz.length > 0
        ? state.customer.quiz.length == 3 &&
            !state.customer.quiz.find(x => x.isDone == 0)
        : false;
    },
    isQuiz1Done: state => {
      return state.customer.quiz.length > 0
        ? state.customer.quiz.find(x => x.level == 1) &&
            state.customer.quiz.find(x => x.level == 1).isDone
        : false;
    },
    isQuiz2Done: state => {
      return state.customer.quiz.length > 0
        ? state.customer.quiz.find(x => x.level == 2) &&
            state.customer.quiz.find(x => x.level == 2).isDone
        : false;
    },
    isQuiz3Done: state => {
      return state.customer.quiz.length > 0
        ? state.customer.quiz.find(x => x.level == 3) &&
            state.customer.quiz.find(x => x.level == 3).isDone
        : false;
    },
    canLuckyDraw: state => {
      return state.customer.entry ? true : false;
    }
  },
  plugins: [localStorage.plugin, sessionStorage.plugin]
});

export default store;
